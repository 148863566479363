import React from "react";
import Layout from "../../../components/layout"
import JobsTable from "../../../components/JobsTable";
import HeaderFRIT from "../../../components/HeaderFRIT";
import FooterFRIT from "../../../components/FooterFRIT";

const columns = [
    {id: 'job', label: 'Postes', minWidth: 170, link: "link"},
];

function createData(job, link) {
    return {job, link};
}


const rows = [

        createData('Ingénieur en informatique', 'https://gnb1.hiringplatform.ca/24723-computer-engineer/77221-registration/fr_CA'),
        createData('Programmeur informatique et développeur de médias interactif', 'https://gnb1.hiringplatform.ca/24727-software-engineer-and-designer/77226-registration/fr_CA'),
        createData('Analyste et consultant en systèmes d\'information', 'https://gnb1.hiringplatform.ca/24611-information-systems-analysts-and-consultants/77095-registration/fr_CA'),
        createData('Analyste de bases de données et administrateur de données', 'https://gnb1.hiringplatform.ca/24731-database-analyst-and-data-administrator/77231-registration/fr_CA'),
        createData('Ingénieur et concepteur logiciels', 'https://gnb1.hiringplatform.ca/24738-computer-programmer-and-interactive-media-developer/77237-registration/fr_CA'),
        createData('Concepteur et développeur Web', 'https://gnb1.hiringplatform.ca/24739-web-designer-and-developer/77240-registration/fr_CA'),
        createData('Technicien de réseau informatique', 'https://gnb1.hiringplatform.ca/24747-computer-network-technician/77248-registration/fr_CA'),
        createData('Technicien d\'assistance aux utilisateurs', 'https://gnb1.hiringplatform.ca/24751-user-support-technician/77252-registration/fr_CA'),
        createData('Technicien en test de systèmes d\'information', 'https://gnb1.hiringplatform.ca/24761-information-system-testing-technician/77258-registration/fr_CA'),
        createData('Ventes / Marketing / Développement commercial', 'https://gnb1.hiringplatform.ca/24764-sales-marketing-business-development/77261-registration/fr_CA'),
        createData('Gestionnaire de compte', 'https://gnb1.hiringplatform.ca/24766-account-manager/77265-registration/fr_CA'),
        createData('Gestionnaire de projet', 'https://gnb1.hiringplatform.ca/24772-project-manager/77270-registration/fr_CA'),

    ]
;
const Index = () => (
    <Layout>
        <HeaderFRIT/>
        <div className={"container-xl tac-lg"}>
             <h2 className={'tac'}>Industrie des TI du Nouveau-Brunswick</h2>
            <h2 className={'tac pb5'}>Connecteur de travail virtuel</h2>
            <p><b>Les employeurs en informatique du Nouveau-Brunswick recherchent des centaines de professionnels ayant de l'expérience dans une variété de professions!</b></p>
            <p><b>Des représentants tels que CompuTech, IGT, Appcast, Innovatia, Introhive, Moneris, Parados, PQA Testing, Smart Skin Technologies, TrojAI, Spandrel Interactive et VidCruiter sont à la recherche de talents et organiseront des entrevues de découverte avec des candidats potentiels.
            </b></p>
            <div className={"container-md py4"}>
                <p>Transférez vos compétences sur la côte est du Canada! Au Nouveau-Brunswick, vous trouverez:</p>

                <ul className={"pl4 pl0-md tal"}>
                    <li>Petits trajets</li>
                    <li>Coûts de logement abordable</li>
                    <li>Systèmes scolaires anglais et français</li>
                    <li>Éducation postsecondaire accessible</li>
                    <li>Opportunités de carrière</li>
                </ul>
            </div>

            <p>Pour plus d’informations sur les mesures de soutien à l’emploi, veuillez visiter le site Web du gouvernement du Nouveau-Brunswick <a target={"_blank"} href={"http://www.travailnb.ca/"}>Travail NB</a></p>


            <p><u>De plus en plus d'entreprises s'inscrivent quotidiennement!</u></p>

            <div className={"c jc ac"}>
                <h2 className={"py4"}>Tous les emplois provinciaux</h2>
            </div>
        </div>
        <div className={"container-md c ac jc"}>
            <JobsTable rows={rows} columns={columns}/>

        </div>
        <FooterFRIT/>
    </Layout>
);

export default Index;