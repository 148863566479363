import gnbLogo from "../images/NB-LOGO.svg";
import React from "react";
import {Link} from "gatsby"
import Helmet from "react-helmet";
import SEO from "./seo";

export default function HeaderFR(props) {
    return (<>
        <Helmet
            bodyAttributes={{
                class: 'positions-page'
            }}
        />
        <SEO description="Connecteur d'emploi du Nouveau-Brunswick"
             title="Connecteur d'emploi du Nouveau-Brunswick"/>
        <div className={"b-gradient"}/>
        <div className={"container-xxl mt3"}>
            <div className={"r-c-lg ac jsb"}>
                <Link to="/"><img className={"my4"} width="216" height="93" src={gnbLogo} alt="Logo du GNB"/></Link>
                <div className={"c jc afe ac-lg"}>
                    <h2 className={"tar tac-lg mb2-lg"}>Bienvenue au connecteur d'emploi</h2>
                    <h2 className={"tar tac-lg mb3-lg"}>TI du Nouveau-Brunswick</h2>
                     <p className={"tar mb0 fs-small tac-lg mt1 pb3-lg c-grape"}><b>Entrevues de découverte pour les étudiant(e)s de NBCC/CCNB du 17 au 19 mars</b>
                    </p>
                    <p className={"tar mb0 fs-extra-small tac-lg mt1 pb3-lg c-gray"}><b>Entrevues de découverte pour tous les autres professionnels de TI du 1 au 15 avril</b>
                    </p>
                    <Link className={"balance-link"} target="_blank"
                          to={"https://www2.gnb.ca/content/gnb/fr/corporate/promo/trouvez_votre_equilibre.html"}>
                        <p className={"tar mb0 fs-small tac-lg mt1 pb3-lg c-grape"}>Cliquez ici pour en savoir plus à
                            propos de vivre et travailler au Nouveau-Brunswick</p>
                    </Link>
                </div>
            </div>
            <hr className={"mb5 mt3"}/>
        </div>
    </>)
}